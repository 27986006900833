import StringLocalized from "../../../Language/language.lang";

import ReportsIcon from "../../../Icons/sidebarIcons/Reports/reports.icon";

const SideBarData = [
  {
    title: StringLocalized.Dashboard,
    icon: <ReportsIcon />,
    path: "/dashboard/admin",
    role: "dashboard",
    accessRole: "menu",
    // single: true,
  },

  {
    title: StringLocalized.EntryAndExitLogs,
    icon: <ReportsIcon />,
    path: "/login-logout-log",
    role: "entry",
    accessRole: "menu",
    // single: true,
  },
  {
    title: StringLocalized.DashboardCarOwner,
    icon: <ReportsIcon />,
    path: "/dashboard/carowner",
    role: "fleet_dashboard",
    accessRole: "menu",
    // single: true,
  },
  {
    title: StringLocalized.orders,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.ListTrips,
        icon: <ReportsIcon />,
        path: "/trips",
        role: "trip",
        accessRole: "menu",
      },
      {
        title: StringLocalized.ListArchive,
        icon: <ReportsIcon />,
        path: "/archive-order",
        role: "archive_trip",
        accessRole: "menu",
      },
      {
        title: StringLocalized.ListOffers,
        icon: <ReportsIcon />,
        path: "/list-offers",
        role: "offer",
        accessRole: "menu",
      },
      {
        title: StringLocalized.Booked,
        icon: <ReportsIcon />,
        path: "/booked",
        role: "booked",
        accessRole: "menu",
      },
      {
        title: StringLocalized.TravelPoint,
        icon: <ReportsIcon />,
        path: "/point-travels-list",
        role: "trip_rating",
        accessRole: "menu",
      },
    ],
  },
  {
    title: StringLocalized.Registers,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.ManagementAdmins,
        icon: <ReportsIcon />,
        path: "/admins",
        role: "users",
        accessRole: "menu",
      },
      {
        title: StringLocalized.LastChange,
        icon: <ReportsIcon />,
        path: "/last-changes",
        role: "changes",
        accessRole: "menu",
      },
      {
        title: StringLocalized.LastChangeAdmin,
        icon: <ReportsIcon />,
        path: "/last-changes-users",
        role: "latest_user_management",
        accessRole: "menu",
      },
      {
        title: StringLocalized.UserGroups,
        icon: <ReportsIcon />,
        path: "/user-groups",
        role: "roles",
        accessRole: "menu",
      },
      {
        title: StringLocalized.ListPassenger,
        icon: <ReportsIcon />,
        path: "/passengers",
        role: "passenger",
        accessRole: "menu",
      },
      {
        title: StringLocalized.DriverList,
        icon: <ReportsIcon />,
        path: "/drivers",
        role: "driver",
        accessRole: "menu",
      },
      {
        title: StringLocalized.CarsList,
        icon: <ReportsIcon />,
        path: "/cars",
        role: "car",
        accessRole: "menu",
      },

      {
        title: StringLocalized.DeletedCarsList,
        icon: <ReportsIcon />,
        path: "/deleted-cars",
        role: "deleted_car",
        accessRole: "menu",
      },
      {
        title: StringLocalized.ListCarOwner,
        icon: <ReportsIcon />,
        path: "/car-owners",
        role: "fleet",
        accessRole: "menu",
      },
    ],
  },
  {
    title: StringLocalized.DynamicItems,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.AdsList,
        icon: <ReportsIcon />,
        path: "/ads",
        role: "ads",
        accessRole: "menu",
      },
      {
        title: StringLocalized.ServiceList,
        icon: <ReportsIcon />,
        path: "/services",
        role: "service",
        accessRole: "menu",
      },
      {
        title: StringLocalized.PenaltyCancelingPassenger,
        icon: <ReportsIcon />,
        path: "/penalty",
        role: "penalty",
        accessRole: "menu",
      },
      {
        title: StringLocalized.ListDiscount,
        icon: <ReportsIcon />,
        path: "/list-discount",
        role: "discount",
        accessRole: "menu",
      },
      {
        title: StringLocalized.ListCommissionCoefficient,
        icon: <ReportsIcon />,
        path: "/commission-coefficient",
        role: "commission",
        accessRole: "menu",
      },
      {
        title: StringLocalized.PriceModal,
        icon: <ReportsIcon />,
        path: "/price-model",
        role: "price_model",
        accessRole: "menu",
      },
      {
        title: StringLocalized.FareIncreaseHour,
        icon: <ReportsIcon />,
        path: "/fare-increase-hour",
        role: "fare",
        accessRole: "menu",
      },
      {
        title: StringLocalized.IssuesList,
        icon: <ReportsIcon />,
        path: "/issues",
        role: "issue",
        accessRole: "menu",
      },
      {
        title: StringLocalized.SafetyShieldList,
        icon: <ReportsIcon />,
        path: "/safety-shields",
        role: "shield",
        accessRole: "menu",
      },
    ],
  },
  {
    title: StringLocalized.ListNotification,
    icon: <ReportsIcon />,
    path: "/notifications",
    role: "notification",
    accessRole: "menu",
  },
  {
    title: StringLocalized.MainSettings,
    icon: <ReportsIcon />,
    path: "/main-setting",
    role: "settings",
    accessRole: "menu",
    // single: true,
  },

  {
    title: StringLocalized.Reports,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.AdminMap,
        icon: <ReportsIcon />,
        path: "/admin-map",
        role: "admin_map",
        accessRole: "menu",
      },
      {
        title: StringLocalized.MonthlyExpenceReport,
        icon: <ReportsIcon />,
        path: "/monthly-expense-report",
        role: "monthly_expense",
        accessRole: "menu",
      },
      {
        title: StringLocalized.MonthlyIncomeReport,
        icon: <ReportsIcon />,
        path: "/monthly-income-report",
        role: "monthly_income",
        accessRole: "menu",
      },
    ],
  },
  // {
  //   title: StringLocalized.payList,
  //   icon: <ReportsIcon />,
  //   path: "/request-pays",
  //   role: "payment",
  //   accessRole: "menu",
  //   // single: true,
  // },
  // {
  //   title: StringLocalized.ShareScreen,
  //   icon: <ReportsIcon />,
  //   path: "/share-screen",
  //   role: "shared_trip",
  //   accessRole: "menu",
  //   // single: true,
  // },
  {
    title: StringLocalized.DriversFleet,
    icon: <ReportsIcon />,
    path: "/drivers-fleet",
    role: "fleet_driver",
    accessRole: "menu",
  },
  {
    title: StringLocalized.CarsListFleet,
    icon: <ReportsIcon />,
    path: "/cars-fleet",
    role: "fleet_car",
    accessRole: "menu",
  },
  {
    title: StringLocalized.ReportsFleet,
    icon: <ReportsIcon />,
    path: "/report-fleet",
    role: "fleet_report",
    accessRole: "menu",
  },
  {
    title: StringLocalized.Invoices,
    icon: <ReportsIcon />,
    child: [
      {
        title: StringLocalized.FleetToRider,
        icon: <ReportsIcon />,
        path: "/fleet-rider",
        role: "fleet_rider",
        accessRole: "menu",
      },
      {
        title: StringLocalized.PikgoToFleet,
        icon: <ReportsIcon />,
        path: "/pikgo-fleet",
        role: "pikgo_fleet",
        accessRole: "menu",
      },
      {
        title: StringLocalized.payouts,
        icon: <ReportsIcon />,
        path: "/payouts",
        role: "payouts",
        accessRole: "menu",
      },
    ],
  },
  {
    title: StringLocalized.OrderFleet,
    icon: <ReportsIcon />,
    path: "/order-fleet",
    role: "order_fleet",
    accessRole: "menu",
    // single: true,
  },
  {
    title: StringLocalized.Offers,
    icon: <ReportsIcon />,
    path: "/offer-fleet",
    role: "offer_fleet",
    accessRole: "menu",
    // single: true,
  },
  {
    title: StringLocalized.LiveMap,
    icon: <ReportsIcon />,
    path: "/fleet-map",
    role: "fleet_map",
    accessRole: "menu",
  },
  {
    title: StringLocalized.ExpiringDocuments,
    icon: <ReportsIcon />,
    path: "/expiring",
    role: "expired_documents",
    accessRole: "menu",
    // single: true,
  },
  {
    title: StringLocalized.Balance,
    icon: <ReportsIcon />,
    path: "/balance",
    role: "balance",
    accessRole: "menu",
    // single: true,
  },
  {
    title: StringLocalized.SettingFleet,
    icon: <ReportsIcon />,
    path: "/setting-fleet",
    role: "fleet_setting",
    accessRole: "menu",
    // single: true,
  },
  {
    title: StringLocalized.Logout,
    icon: <ReportsIcon />,
    path: "/login",
    single: true,
  },
];

export default SideBarData;
