import LocalizedStrings from "react-localization";
import axiosInstance from "../HelperAxios/axios";

let language = localStorage.getItem("lang");

const strings = new LocalizedStrings({
  en: require("./en.json"),
  fa: require("./fa.json"),
});
if (language) {
  strings.setLanguage(language);
}
else {
  strings.setLanguage("en");
}

export default strings;
