import {
  TopMenuContainer,
  ListItem,
  Sidebar,
} from "./topMenuStyles/topMenuStyles.style";
import * as proptypes from "prop-types";
import { useState } from "react";
import MenuHamberger from "../../Icons/sidebarIcons/topSidebarIcon/SidebarIcon";
// import { Link } from "react-router-dom";
// import { topMenuData } from "./topMenuData/topMenu.data";

const TopMenu = ({ setWithSidebar }) => {
  let today = new Date().toLocaleDateString();
  const [widthHandle, setWithHandle] = useState(true);
  const handleSidebar = () => {
    setWithHandle(!widthHandle);
    widthHandle === false ? setWithSidebar("f1") : setWithSidebar("f2");
  };

  return (
    <TopMenuContainer
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}
    >
      {/* <Sidebar> */}
        <ListItem className="px-6" onClick={() => handleSidebar()} style={{ cursor: "pointer" }}>
          <MenuHamberger widthHandle={widthHandle} />
        </ListItem>
      {/* </Sidebar> */}
      {/* <div
        style={{
          fontSize: "8px",
          // position: "absolute",
          // right: "22px",
        }}
      > */}
        <ListItem style={{ margin: 0, paddingRight: 10, paddingLeft: 10 }}>{today}</ListItem>
      {/* </div> */}
    </TopMenuContainer>
  );
};

export default TopMenu;
TopMenu.propTypes = {
  setWithSidebar: proptypes.func,
};
