import { useState } from "react";
import {
  ListIcon,
  ListItemTxt,
  SideBarHeaderText,
  ListItemBtn,
  List,
} from "./sidebarStyles/listItem.style";
import Chevron from "../../Icons/sidebarIcons/chevron/chevron.icon";
import proptypes from "prop-types";
import { handleOpenClose } from "./sidebarUtils/listItem.utils";
import { Collapse } from "@mui/material";
import { goToPage, isActive } from "./sidebarUtils/listItem.utils";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { textColor } from "../../colors/allColors.color";
import axiosInstance from "../../HelperAxios/axios";
import axios from "axios";
import { API_URL_PORTAL_USER } from "../../HelperAxios/const";

// this component implemented recursively if child exist invoked List item again in render another list item and repeat ...
const ListItem = ({
  title,
  icon: Icon,
  child,
  headerListItem,
  asChildlren,
  parentpath,
  menuColor,
  subMenuColor,
  ...rest
}) => {
  const [open, setOpen] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(parentpath, isActive(parentpath, location.pathname));
  const token = JSON.parse(localStorage.getItem("token-pikgo"));
  const exit = (path) => {
    axios
      .post(
        `${API_URL_PORTAL_USER}/logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        navigate(path);
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  };

  return (
    <>
      <ListItemBtn
        menuColor={menuColor}
        subMenuColor={subMenuColor}
        onClick={() => {
          if (parentpath === "/login") {
            exit(parentpath); // فراخوانی تابع خروج
          } else {
            parentpath
              ? goToPage(parentpath, navigate)
              : handleOpenClose(setOpen, open, child)();
          }
        }}
        isactive={parentpath && isActive(parentpath, location.pathname)}
        /**
         * situation number 1 : when we have children property in our object this means ListItemBtn should render as a Parent Btn
         * which has different Color (should have Background Color spicificly for parent role) ***asParent prop defined for this situation***
         * situation number 2 : asChildren Prop passed to ListItemBtn when we have children property which ListItemBtn should render as a
         * children which has different Color (take a look at line 51) ***asChildren props defined for this situation***
         * situation number 3 : when there is no child and there is no parent then ListItemBtn background color is the same background color which parent color is
         */
        aschidlren={asChildlren}
        asparent={child}
        headerlistitem={String(headerListItem)}
      >
        <ListIcon headerlistitem={String(headerListItem)}>{Icon}</ListIcon>
        {/* 
          there is a two type of list item inside sidebar menu one type is list item header which is on top of side bar
          the text of this list item is  bolder and greater than the other and if the headerListItem prop doesnt exist this 
          means our list item type is regular list type 
          this means we should render <ListItemTxt /> component not <SideBarHeaderText
       */}
        {headerListItem ? (
          <SideBarHeaderText>{title}</SideBarHeaderText>
        ) : asChildlren === "true" ? (
          <Link
            to={`${rest.path}`}
            style={{
              width: "100%",
              textDecoration: "none",
              color: textColor,
            }}
          >
            <ListItemTxt primary={title} />
          </Link>
        ) : !child ? (
          <Link
            to={`${parentpath}`}
            style={{
              width: "100%",
              textDecoration: "none",
              color: textColor,
            }}
          >
            <ListItemTxt primary={title} />
          </Link>
        ) : (
          <ListItemTxt primary={title} />
        )}
        {child && <Chevron />}
      </ListItemBtn>

      {/* if child exist invoke ListItem again and render child Items (recursive function) */}
      {child && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          sx={{ padding: "4px 0" }}
        >
          {child.map((childItem, index) => (
            <List
              key={index}
              component="div"
              disablePadding
              onClick={() => goToPage(childItem.path, navigate)}
              isactive={isActive(childItem.path, location.pathname)}
              menuColor={menuColor}
            >
              <ListItem
                subMenuColor={subMenuColor}
                {...childItem}
                asChildlren={"true"}
              />
            </List>
          ))}
        </Collapse>
      )}
    </>
  );
};

// component props type checking
ListItem.propTypes = {
  title: proptypes.string.isRequired,
  icon: proptypes.element.isRequired,
  menuColor: proptypes.string,
  subMenuColor: proptypes.string,
  headerListItem: proptypes.bool,
  asChildlren: proptypes.string,
  parentpath: proptypes.string,
  child: proptypes.arrayOf(
    proptypes.shape({
      title: proptypes.string,
      icon: proptypes.element,
    })
  ),
};

export default ListItem;
